.tech-cards {
  justify-content: center;
}
.tech-heading {
  font-size: 72px;
  display: flex;
  text-align: center;
  font-weight: 800;
  line-height: 1;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  position: absolute;
  top: 120px;
  margin-left: -35px;
  left: 200px;
}
.tech-text {
  font-weight: 600;
  font-size: 18px;
  display: flex;
  text-align: center;
  margin-top: -60px;
}
.tech-row {
  margin-bottom: 230px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 369px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .tech-heading {
    font-size: 26px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 150px;
    left: 60px;
    color: black;
  }

  .service-img {
    width: 240px;
    height: 100;
    top: 20px;
    position: relative;
    left: -30px;
  }
  .tech-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -90px;
  }
}

@media only screen and (min-device-width: 369px) and (max-device-width: 405px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .tech-heading {
    font-size: 28px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 150px;
    left: 60px;
    color: black;
    margin-top: 20px;
  }

  .service-img {
    width: 240px;
    height: 100;
    margin-left: 100px;
  }
  .tech-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 80px;
    position: relative;
    top: -90px;
  }
}

@media only screen and (min-device-width: 405px) and (max-device-width: 455px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .tech-heading {
    font-size: 28px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 170px;
    left: 90px;
    color: black;
  }

  .service-img {
    width: 240px;
    height: 100;
    margin-left: 100px;
  }
  .tech-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -70px;
  }
}
@media only screen and (min-device-width: 456px) and (max-device-width: 555px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .tech-heading {
    font-size: 35px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 140px;
    left: 80px;
    color: black;
  }

  .service-img {
    width: 240px;
    height: 100;
    margin-left: 100px;
  }
  .tech-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -70px;
  }
}

@media only screen and (min-device-width: 556px) and (max-device-width: 700px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .tech-heading {
    font-size: 37px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 170px;
    left: 120px;
    color: black;
  }

  .service-img {
    width: 240px;
    height: 100;
    margin-left: 100px;
  }
  .tech-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -40px;
  }
}
@media only screen and (min-device-width: 700px) and (max-device-width: 800px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .tech-heading {
    font-size: 45px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 120px;
    left: 140px;
    color: black;
  }

  .service-img {
    width: 240px;
    height: 100;
    margin-left: 50px;
  }
  .tech-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -50px;
  }
}
@media only screen and (min-device-width: 800px) and (max-device-width: 961px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .tech-heading {
    font-size: 50px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 170px;
    left: 130px;
    color: black;
  }

  .service-img {
    width: 240px;
    height: 100;
    margin-left: 500px;
  }
  .tech-text {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -40px;
  }
}
@media only screen and (min-device-width: 962px) and (max-device-width: 1158px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .tech-heading {
    font-size: 55px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 170px;
    left: 240px;
    color: black;
  }

  .service-img {
    width: 240px;
    height: 100;
    margin-left: 500px;
  }
  .tech-text {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -20px;
  }
}

@media only screen and (min-device-width: 1159px) and (max-device-width: 1500px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .tech-heading {
    font-size: 65px;
    display: flex;
    margin-top: 100px;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 40px;
    left: 360px;
    color: black;
  }
  .service-img {
    height: 200px;
    width: "100%";
    position: relative;
  }
  .tech-text {
    font-size: 20px;
    font-weight: 500;
    margin-top: 130px;
    display: flex;
    text-align: center;
    margin-left: 12px;

    position: relative;
    top: -60px;
  }
}
@media only screen and (min-device-width: 1500px) and (max-device-width: 1599px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .tech-heading {
    font-size: 85px;
    display: flex;
    margin-top: 100px;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 180px;
    left: 320px;
    color: black;
  }

  .service-img {
    margin-left: 400px;
    height: 200px;
    width: "100%";
    position: relative;
  }
  .tech-text {
    font-size: 24px;
    font-weight: 500;
    margin-top: 130px;
    display: flex;
    text-align: center;
    margin-left: 12px;

    position: relative;
    top: -90px;
  }
}
@media only screen and (min-device-width: 1600px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .tech-heading {
    font-size: 95px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 180px;
    left: 300px;
    color: black;
    margin-top: 150px;
  }
  .service-img {
    width: 420px;
    height: 400px;
    margin-top: 150px;
    margin-left: 100px;
  }
  .tech-text {
    font-size: 24px;
    font-weight: 500;
    display: flex;
    text-align: center;
    left: -80px;
    margin-bottom: 340px;
    position: relative;
    top: 150px;
  }
}

@media only screen and (min-device-width: 1780px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .tech-heading {
    font-size: 115px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 80px;
    left: 200px;
    color: black;
    margin-top: 150px;
  }
  .service-img {
    width: 420px;
    height: 400px;
    margin-top: 150px;
    margin-left: 1200px;
  }
  .tech-text {
    font-size: 24px;
    font-weight: 500;
    display: flex;
    text-align: center;
    left: -20px;
    margin-bottom: 340px;
    position: relative;
    top: 120px;
  }
}
@media only screen and (min-device-width: 1899px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .tech-heading {
    font-size: 120px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 180px;
    left: 280px;
    color: black;
    margin-top: 150px;
  }
  .service-img {
    width: 420px;
    height: 400px;
    margin-top: 150px;
    margin-left: 1200px;
  }
  .tech-text {
    font-size: 24px;
    font-weight: 500;
    display: flex;
    text-align: center;
    left: 20px;
    margin-bottom: 340px;
    position: relative;
    top: 120px;
  }
}
