.project-one {
  width: 600px;
  display: flex;
  height: 400px;
  margin-bottom: 30px;
}
.project-ten {
  width: "100px";

  height: "50px";
}
.project-twel {
  width: 800px;
  display: flex;
  height: 800px;
}
.project-two {
  width: 300px;
  justify-content: center;
  display: flex;
  height: 200px;
  margin-bottom: 30px;
}
.headingtwo {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 50px;
  letter-spacing: -1px;
  display: flex;
  justify-content: flex-start;
  line-height: 90%;
  margin-top: 60px;
  margin-bottom: 90px;
}
.headingtwotwo {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 50px;
  letter-spacing: -1px;
  display: flex;
  justify-content: flex-start;
  line-height: 90%;
  margin-top: 60px;
  margin-bottom: 90px;
}
.headingthree {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 20px;
  letter-spacing: -1px;
  margin-top: -16px;
  margin-left: 15px;
  line-height: 90%;
}
.project-font {
  position: "relative";
  top: -50px;
  font-size: 30px;
}
.project-img {
  width: 130px;
  height: 130px;
  margin-top: 25px;
  margin-bottom: 25px;
  border-radius: 50%;
  /* padding: 25px; */
}
.project-text {
  font-family: sans-serif;
  color: grey;
  font-size: 16px;
  margin-left: 12px;
}
.project-ten {
  justify-content: center;
  margin-bottom: 30px;
}
.project-eleven {
  margin-top: 30px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 22px;
  font-style: italic;
}
.project-test {
  margin-left: 20px;
}

@media only screen and (max-width: 768px) {
  /* For mobile: */
  .project-two {
    width: 310px;
    justify-content: center;
    display: flex;
    height: 300px;
    margin-bottom: 30px;
  }
  .project-test {
    margin-left: 80px;
  }

  .project-eleven {
    justify-content: center;
    margin-top: 30px;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 18px;
    font-style: italic;
  }
  .project-ten {
    display: none;
  }
  .project-one {
    width: 400px;
    display: flex;
    margin-top: 20px;
  }
  .headingtwotwo {
    display: none;
  }
  .project-img {
    width: 180px;
    height: 180px;
    align-self: center;
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 50%;
  }
  .project-text {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    color: grey;
    font-size: 18px;
    margin-left: 24px;
  }
  .headingthree {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 24px;
    letter-spacing: -1px;
    margin-top: -16px;
    margin-left: 24px;
    line-height: 90%;
  }
  .project-ten {
    width: 500px;
    display: flex;
    height: 500px;
  }
}
