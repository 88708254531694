.career-heading {
  font-size: 75px;
  display: flex;
  text-align: center;
  font-weight: 800;
  line-height: 1;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  position: absolute;
  top: 120px;
  left: 220px;
}
.career-heading1 {
  font-size: 75px;
  display: flex;
  text-align: center;
  font-weight: 800;
  line-height: 1;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.career-section1 {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 800;
  display: flex;
  margin-bottom: 40px;
  justify-content: center;
}
.career-image {
  width: 900px;
  margin-top: 15px;
}

.career-sec2 {
  background-color: grey;
  border: none;
  padding: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 1%;
}
.career-text {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  text-align: center;
  margin-left: -70px;
  position: relative;
  top: -50px;
}
.career-card {
  position: relative;
  top: -350px;
  border: none;
  border-radius: 1px;
  padding: 5px;
  left: 190px;
  height: 60px;
  width: max-content;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.career-card2 {
  position: relative;
  top: -270px;
  border: none;
  width: max-content;
  border: none;
  height: 40px;
  border-radius: 1px;
  padding: 5px;
  left: 110px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.career-card5 {
  position: relative;
  top: -300px;
  border: none;
  width: max-content;
  font-size: 12px;
  border-radius: 1px;
  height: 30px;
  border-radius: 1px;
  padding: 5px;
  left: 210px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.career-card3 {
  padding: 5px;
  width: 130px;
  height: 40px;

  position: relative;
  width: max-content;
  border: none;
  border-radius: 1px;
  top: -150px;
  left: -270px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.career-card4 {
  padding: 15px;
  justify-content: center;
  display: flex;
  position: relative;
  width: max-content;
  height: 50px;
  padding-top: 30px;
  top: -140px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.career-photo1 {
  display: flex;
  justify-content: center;
}
.career-cardtext {
  position: relative;
  left: 120px;
  top: 110px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  display: flex;
  padding: 25px;
  border-radius: 1%;
  border: none;
}
.career-photo {
  width: 750px;
  height: 500px;
  display: flex;
  margin-bottom: 70px;
  justify-content: center;
}
.career-row {
  margin-bottom: 230px;
}
@media only screen and (max-width: 768px) {
  .career-row {
    margin-bottom: 230px;
    margin-top: 70px;
  }
  .career-card {
    position: relative;
    top: -180px;
    padding: 5px;
    left: 50px;
    width: max-content;
    height: 40px;
    font-size: 12px;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  .career-image {
    width: 400px;
    margin-top: 15px;
  }
  .career-section1 {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-weight: 800;
    font-size: 30px;
    margin-bottom: 70px;
    display: flex;
    margin-top: 150px;
    justify-content: center;
  }

  .career-photo {
    width: 400px;
    height: 400px;
    margin-top: -150px;
  }
  .career-card2 {
    padding: 5px;
    position: relative;
    top: -120px;
    height: 30px;
    border: none;
    width: max-content;
    font-size: 12px;
    left: 40px;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  .career-sec2 {
    background-color: grey;
    border: none;
    border-radius: 1%;
  }
  .career-cardtext {
    position: relative;
    left: 10px;
    top: 360px;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    display: flex;
    padding: 10px;
    border-radius: 1%;
    border: none;
  }
  .career-hero {
    margin-top: 70px;
  }
  .career-card3 {
    padding: 5px;
    width: max-content;
    font-size: 12px;
    position: relative;
    top: -50px;
    height: 30px;

    left: -200px;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  .career-card4 {
    padding: 5px;
    justify-content: center;
    display: flex;
    width: max-content;
    font-size: 12px;
    height: 45px;
    position: relative;
    top: -100px;
    left: 140px;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  .career-card5 {
    position: relative;
    top: -200px;
    border: none;
    width: max-content;
    font-size: 12px;
    border-radius: 1px;
    height: 30px;
    border-radius: 1px;
    padding: 5px;
    left: 100px;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 369px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .career-heading {
    font-size: 25px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 170px;
    margin-top: -80px;
    left: 40px;
    color: black;
  }

  .service-img {
    width: 240px;
    height: 100;
    margin-left: 250px;
  }
  .career-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 5px;
    position: relative;
    top: -150px;
  }
}

@media only screen and (min-device-width: 369px) and (max-device-width: 405px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .career-heading {
    font-size: 28px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 160px;
    left: 40px;
    color: black;
    margin-top: -30px;
  }

  .service-img {
    width: 240px;
    height: 100;
    margin-left: 250px;
  }
  .career-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 80px;
    position: relative;
    top: -150px;
  }
}

@media only screen and (min-device-width: 405px) and (max-device-width: 455px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .career-heading {
    font-size: 33px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 170px;
    margin-top: -30px;
    left: 30px;
    color: black;
  }

  .service-img {
    width: 240px;
    height: 100;
    margin-top: 40px;
    margin-left: 300px;
  }
  .career-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 10px;
    position: relative;
    top: -70px;
  }
}

@media only screen and (min-device-width: 455px) and (max-device-width: 555px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .career-heading {
    font-size: 35px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 170px;
    left: 60px;
    color: black;
  }

  .service-img {
    width: 240px;
    height: 100;
    margin-left: 100px;
  }
  .career-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -70px;
  }
}
@media only screen and (min-device-width: 555px) and (max-device-width: 700px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .career-heading {
    font-size: 40px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 170px;
    left: 90px;
    color: black;
  }
  .career-cardtext {
    display: none;
  }
  .service-img {
    width: 240px;
    height: 100;
    margin-left: 100px;
  }
  .career-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -70px;
  }
}
@media only screen and (min-device-width: 700px) and (max-device-width: 805px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .career-heading {
    font-size: 45px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 140px;
    left: 90px;
    color: black;
  }
  .career-cardtext {
    display: none;
  }
  .service-img {
    width: 240px;
    height: 100;
    margin-left: 50px;
  }
  .career-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -50px;
  }
}
@media only screen and (min-device-width: 805px) and (max-device-width: 961px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .career-heading {
    font-size: 55px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 170px;
    left: 180px;
    color: black;
  }
  .career-cardtext {
    display: none;
  }
  .service-img {
    width: 240px;
    height: 100;
    margin-left: 500px;
  }
  .career-text {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -40px;
  }
}
@media only screen and (min-device-width: 962px) and (max-device-width: 1500px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .career-heading {
    font-size: 65px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 130px;
    left: 300px;

    color: black;
  }
  .service-img {
    width: 240px;
    height: 100;
    margin-left: 300px;
  }
  .career-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    margin-top: 20px;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -20px;
  }
}

@media only screen and (min-device-width: 1501px) and (max-device-width: 1899px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .career-heading {
    font-size: 75px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    margin-top: 50px;
    position: absolute;
    top: 230px;
    left: 390px;
    color: black;
  }

  .service-img {
    width: 360px;
    height: 300px;
    margin-top: 150px;
    margin-left: 800px;
  }
  .career-text {
    font-size: 24px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 122px;
    margin-top: 40px;
    position: relative;
    top: -10px;
    margin-bottom: 280px;
  }
}
@media only screen and (min-device-width: 1900px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .career-heading {
    font-size: 90px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 170px;
    left: 500px;
    color: black;
    margin-top: 150px;
  }
  .service-img {
    width: 420px;
    height: 400px;
    margin-top: 150px;
    margin-left: 1200px;
  }
  .career-text {
    font-size: 30px;
    font-weight: 500;
    display: flex;
    text-align: center;
    left: 80px;
    margin-bottom: 340px;
    position: relative;
    top: 20px;
  }
}
