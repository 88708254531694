.overlap {
  position: relative;
  top: 45%;
  color: white;
  font-size: 60px;
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.newimage {
  height: 400px;
  width: 500px;
}
@media screen and (max-width: 990px) {
  .overlap {
    position: relative;
    top: 45%;
    color: white;
    font-size: 35px;
    text-align: center;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
}

.about-image {
  width: 600px;
}
.about-last-img {
  width: 1106px;
  height: 500px;
}
.about-heading {
  font-size: 75px;
  display: flex;
  text-align: center;
  font-weight: 800;
  line-height: 1;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  position: absolute;
  top: 120px;
  margin-left: -35px;
  left: 220px;
}
.about-text {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  text-align: start;
}
.about-lastimg {
  position: relative;
  align-self: start;
  bottom: -250px;
  font-size: 60px;
  color: white;
  left: 400px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.about-text1 {
  font-weight: 600;
  font-size: 20px;
}
.abouthead-text {
  font-size: 18px;
  display: flex;
  text-align: center;
  margin-left: -40px;
  font-weight: 500;
  position: relative;
  top: -40px;
}
.about-head {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 900;
  text-align: center;
  font-size: 40px;
}
.about-row {
  margin-bottom: 170px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 369px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .about-heading {
    font-size: 26px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 150px;
    margin-top: -60px;
    left: 60px;
    color: black;
  }

  .service-img {
    width: 240px;
    height: 100;
    margin-left: 260px;
  }
  .abouthead-text {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -120px;
  }
}

@media only screen and (min-device-width: 369px) and (max-device-width: 405px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .about-heading {
    font-size: 28px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 130px;
    margin-top: 50px;
    left: 60px;
    margin-bottom: 10px;
    color: black;
  }

  .service-img {
    width: 240px;
    height: 100;
    margin-left: 80px;
  }
  .abouthead-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 150px;
    position: relative;
    top: -170px;
  }
}

@media only screen and (min-device-width: 405px) and (max-device-width: 455px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .about-heading {
    font-size: 31px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 150px;
    left: 60px;
    color: black;
  }

  .service-img {
    width: 240px;
    height: 100;
    margin-left: 100px;
  }
  .abouthead-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -70px;
  }
}

@media only screen and (min-device-width: 456px) and (max-device-width: 555px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .about-heading {
    font-size: 37px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 150px;
    left: 60px;
    color: black;
  }

  .service-img {
    width: 240px;
    height: 100;
    margin-left: 100px;
  }
  .abouthead-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -70px;
  }
}
@media only screen and (min-device-width: 555px) and (max-device-width: 700px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .about-heading {
    font-size: 40px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 150px;
    left: 90px;
    color: black;
  }

  .service-img {
    width: 240px;
    height: 100;
    margin-left: 100px;
  }
  .abouthead-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -70px;
  }
}
@media only screen and (min-device-width: 700px) and (max-device-width: 800px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .about-heading {
    font-size: 50px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 120px;
    left: 100px;
    color: black;
  }
  .about-image {
    width: 300px;
  }
  .service-img {
    width: 240px;
    height: 200px;
    margin-left: 50px;
  }
  .abouthead-text {
    font-size: 15px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -50px;
  }
}
@media only screen and (min-device-width: 800px) and (max-device-width: 961px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .about-heading {
    font-size: 55px;
    display: flex;
    text-align: center;
    font-weight: 800;
    line-height: 1;
    position: absolute;
    top: 150px;
    left: 180px;
    color: black;
  }

  .service-img {
    width: 240px;
    height: 100;
    margin-left: 500px;
  }
  .abouthead-text {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
    position: relative;
    top: -40px;
  }
}
