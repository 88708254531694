.overlay {
  position: absolute;
  top: 30%;
  right: 20%;
}

.heading {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 800;
  text-align: center;
  font-size: 75px;
  line-height: 1;
}

@media screen and (max-width: 990px) {
  .overlay {
    position: absolute;
    top: 28%;
    right: 5%;
  }

  .heading {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-weight: 800;
    text-align: center;
    font-size: 45px;
    line-height: 1;
  }
}
