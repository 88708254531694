.header {
  font-family: sans-serif;
  font-size: 120;
  font-weight: bold;
  display: flex;
  justify-content: center;
}
.navs {
  text-decoration: none;
  color: grey;
}
.navs:hover {
  color: blue;
}
.img-logo {
  width: 170;
  margin-top: "-30px";
  height: 70;
}
.centered {
  position: relative;
  top: -20%;
  left: 100%;

  transform: translate(-40%, -40%);
}

.nav-hov {
  color: grey;
}

.nav-hov:hover {
  color: #007bff;
}

.centered1 {
  position: relative;
  top: -30%;
  left: 70%;
  font-weight: 600;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  transform: translate(-40%, -40%);
}
.image1 {
  position: relative;
  top: 0;
  left: 0;
}
.header-nav {
  font-family: sans-serif;
  font-weight: 600;
  color: gray;
  font-size: 19px;
}

.logoo {
  /* margin-top: 2px; */
}

@media only screen and (max-width: 768px) {
  .img-logo {
    width: 170;
    height: 70;
  }

  .logoo {
    margin-top: -5px;
  }
}
