.image-head {
  margin-top: 70px;
  width: 250px;
  margin-left: 150px;
}
.image-2 {
  margin-top: -10;
  margin-left: -300px;
  width: "500px";
}

.home-heading2 {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: rgb(0, 0, 0);
  font-weight: 800;
  line-height: 1;
  position: relative;
  top: 100px;
  left: -170px;
  font-size: 75px;
}

.home-subtext {
  font-weight: 600;
  position: relative;
  top: 120px;
  left: -160px;
  color: grey;
}
.Button {
  box-shadow: 20px 20px 50px #dfdfdf;
  position: relative;
  top: 120px;
  left: -160px;
}
.heading1 {
  color: gray;
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.contact-button {
  background-color: black;
}

.home-card {
  border: none;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin-bottom: 8px;
}
.contact-text {
  font-weight: 600;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin-left: "-10px";
}
/* .home-card1 {
  border: none;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin-bottom: 8px;
  background-color: red;
} */

.home-card1:hover {
  background-color: #007bff;
}

.heading2 {
  text-align: left;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 50px;
  letter-spacing: -1px;
  display: flex;
  line-height: 90%;
}

.card-heading {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

/*@media only screen and (max-width: 600px) {
  .home-heading {
    justify-content: center;
    font-size: 32px;
  }
  .heading1 {
    color: blue;
  }
  .image-2 {
    display: none;
  }
  .centered {
    justify-content: center;
    margin-left: 48px;
    margin-top: -250px;
    font-size: 20px;
  }
} */

@media only screen and (max-width: 768px) {
  /* For mobile: */

  .rev {
    display: flex;
    flex-direction: column-reverse;
  }

  .image-2 {
    display: none;
  }
  .contact-text {
    font-weight: 600;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  .stats2 {
    color: red;
    text-align: center;
  }
  .stats-heading {
    font-weight: 700;
    text-align: center;
  }
  .contact-button {
    background-color: black;
  }
  .centered {
    position: relative;
    top: -50px;
    left: 130px;
  }
  .card-heading {
    font-size: 25px;
  }
}
.carousel-control-next,
.carousel-control-prev {
  color: black;
  width: 10px;
  height: 20;
}
.work-card {
  background-color: blue;
  border: none;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: rgb(90, 88, 88);
}
.statistics {
  font-size: 45;
}
.stats2 {
  font-size: 50;
  font-weight: 700;
  color: blue;
  display: flex;
  justify-self: start;
}
.stats-heading {
  font-weight: 700;
  display: flex;
  justify-self: start;
}
.advantages-section {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-align: left;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 481px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .home-heading2 {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    color: rgb(0, 0, 0);
    font-weight: 800;
    line-height: 1;
    position: relative;
    top: -200px;
    left: 10px;
    font-size: 35px;
  }
  .Button {
    box-shadow: 20px 20px 50px #dfdfdf;
    position: relative;
    top: -180px;
    left: 10px;
  }
  .image-head {
    margin-top: 70px;
    width: 250px;
    margin-left: 150px;
  }
  .image-2 {
    display: none;
  }

  .home-subtext {
    font-weight: 600;
    position: relative;
    top: -180px;
    left: 10px;
    color: grey;
  }
}

@media only screen and (min-device-width: 482px) and (max-device-width: 600px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .home-heading2 {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    color: rgb(0, 0, 0);
    font-weight: 800;
    line-height: 1;
    position: relative;
    top: -200px;
    left: 10px;
    font-size: 52px;
  }
  .Button {
    box-shadow: 20px 20px 50px #dfdfdf;
    position: relative;
    top: -180px;
    left: 10px;
  }
  .image-head {
    margin-top: 70px;
    width: 250px;
    margin-left: 150px;
  }
  .image-2 {
    display: none;
  }

  .home-subtext {
    font-weight: 600;
    position: relative;
    top: -180px;
    left: 10px;
    color: grey;
  }
}

@media only screen and (min-device-width: 601px) and (max-device-width: 767px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .home-subtext {
    font-weight: 600;
    position: relative;
    top: -190px;
    left: 10px;
    color: grey;
  }
  .home-heading2 {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    color: rgb(0, 0, 0);
    font-weight: 800;
    line-height: 1;
    position: relative;
    top: -200px;
    left: 10px;
    font-size: 65px;
    margin-top: 200px;
  }
  .Button {
    box-shadow: 20px 20px 50px #dfdfdf;
    position: relative;
    top: -190px;
    left: 10px;
  }
  .image-head {
    margin-top: 70px;
    width: 250px;
    margin-left: 150px;
  }
  .image-2 {
    display: none;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 800px) {
  .home-heading2 {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    color: rgb(0, 0, 0);
    font-weight: 800;
    line-height: 1;

    position: relative;
    top: 100px;
    left: -10px;
    font-size: 65px;
  }
  .Button {
    box-shadow: 20px 20px 50px #dfdfdf;
    position: relative;
    top: 110px;
    left: -10px;
    display: flex;
    text-align: start;
  }
  .image-head {
    margin-top: 30px;
    width: 200;
    margin-left: 300px;
  }
  .image-2 {
    display: none;
  }
  .home-subtext {
    font-weight: 600;
    position: relative;
    top: 110px;
    left: -10px;
    font-size: 20px;
    color: grey;
  }
}
@media only screen and (min-device-width: 801px) and (max-device-width: 999px) {
  .home-heading2 {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    color: rgb(0, 0, 0);
    font-weight: 800;
    line-height: 1;
    position: relative;
    top: 100px;
    left: -10px;
    font-size: 60px;
  }
  .Button {
    box-shadow: 20px 20px 50px #dfdfdf;
    position: relative;
    top: 110px;
    left: -10px;
  }
  .image-head {
    margin-top: 30px;
    width: 200;
    margin-left: 300px;
  }
  .image-2 {
    display: none;
  }
  .home-subtext {
    font-weight: 600;
    position: relative;
    top: 110px;
    font-size: 20px;
    left: -10px;
    color: grey;
  }
}
@media only screen and (min-device-width: 1000px) and (max-device-width: 1440px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .image-2 {
    margin-top: -10;
    margin-left: -300px;
    width: "500px";
  }
  .home-subtext {
    font-weight: 600;
    position: relative;
    top: 120px;
    left: -160px;
    color: grey;
  }

  .home-heading2 {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    color: rgb(0, 0, 0);
    font-weight: 800;
    line-height: 1;
    position: relative;
    top: 100px;
    left: -170px;
    font-size: 70px;
  }

  .Button {
    box-shadow: 20px 20px 50px #dfdfdf;
    position: relative;
    top: 120px;
    left: -160px;
  }
}

@media only screen and (min-device-width: 1441px) and (max-device-width: 1599px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .image-2 {
    margin-top: -10;
    margin-left: -300px;
    width: "500px";
  }

  .home-heading2 {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    color: rgb(0, 0, 0);
    font-weight: 800;
    line-height: 1;
    position: relative;
    top: 100px;
    left: -230px;
    font-size: 80px;
  }

  .home-subtext {
    font-weight: 600;
    position: relative;
    top: 120px;
    left: -220px;
    color: grey;
  }
  .Button {
    box-shadow: 20px 20px 50px #dfdfdf;
    position: relative;
    top: 120px;
    left: -220px;
  }
}
@media only screen and (min-device-width: 1600px) {
  /* Styles */
  /* smartphones, iPhone, portrait 480x320 phones */
  .image-2 {
    margin-top: 390;
    margin-left: -330px;
    width: 700px;
  }

  .home-heading2 {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    color: rgb(0, 0, 0);
    font-weight: 800;
    line-height: 1;
    position: relative;
    top: 100px;
    left: -170px;
    font-size: 85px;
  }
  .image-head {
    margin-top: 70px;
    width: 250px;
    position: relative;
    left: 150px;
  }
  .home-subtext {
    font-weight: 600;
    position: relative;
    top: 120px;
    left: -160px;
    font-size: 20px;
    color: grey;
  }
  .Button {
    box-shadow: 20px 20px 50px #dfdfdf;
    position: relative;
    top: 120px;
    left: -160px;
    margin-bottom: 200px;
  }
}
